import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";
import { useToastContext } from "../../../layout/ToastContext";
const baseURL = process.env.REACT_APP_BASE_URL;

const OtpVerify = () => {
  const { showToast } = useToastContext();
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email.toLowerCase() || "";
  const [otp, setOtp] = useState("");
  const [errorData, setErrorData] = useState("");

  useEffect(() => {
    if (!email) {
      navigate("/404");
    }
  }, [email, navigate]);
  
  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    setErrorData("");
    let validationFailed = false;

    if (!otp) {
      setErrorData("Enter Otp");
      validationFailed = true;
    }
    if (!email) {
      setErrorData("Enter email address");
      validationFailed = true;
    }

    if (validationFailed) {
      return;
    }
    try {
      const response = await fetch(`${baseURL}/auth/verify-otp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, otp }),
      });
      console.log(response);

      if (response.ok) {
        const data = await response.json();
        showToast(data.message, "primary", { autohide: 3000 });
        navigate("/login")
      } else {
        const errorData = await response.json();
        showToast(errorData.error, "danger", { autohide: 5000 });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Verify Otp</h1>
                    <p className="text-medium-emphasis">
                      Enter the otp to verify your account
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="number"
                        placeholder="Enter Otp"
                        id="name"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </CInputGroup>
                    {errorData && (
                      <div style={{ color: "red", paddingBottom: "10px" }}>
                        {errorData}
                      </div>
                    )}
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          color="primary"
                          className="px-4"
                          onClick={handleOTPSubmit}
                        >
                          Submit
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5"
                style={{ width: "44%" }}
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>Login</h2>
                    <p>
                      Let’s create your account Sign up with Salonboost and get
                      Free Trial for 14 Days. No credit card required. No
                      obligations or fixed-term contracts.
                    </p>
                    <Link to="/login">
                      <CButton
                        color="primary"
                        className="mt-3"
                        active
                        tabIndex={-1}
                      >
                        Login Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default OtpVerify;
