import React from "react";
import {
  CRow,
  CCol,
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
} from "@coreui/react";

const Faqs = () => {

  const generalFaqData = [
    {
      question: "What is the best salon management software in India?",
      answer: `SalonBoost is India's leading salon management software designed to automate bookings, billing, and client management.`,
    },
    {
      question: "How does SalonBoost help salon owners?",
      answer: `SalonBoost simplifies salon operations with features like automated appointment booking, customer tracking, and marketing automation.`,
    },
    {
      question: "Does SalonBoost support WhatsApp appointment reminders?",
      answer:
        "Yes, SalonBoost includes automated WhatsApp reminders to reduce no-shows and improve customer engagement.",
    },
    {
      question: "Is there a free trial for SalonBoost?",
      answer: `Yes, we offer a 15-day free trial for salon owners to experience the features of SalonBoost.`,
    },
    {
      question: "Can SalonBoost be used for multiple salon branches?",
      answer: `Yes, SalonBoost supports multi-location salons, allowing owners to manage multiple branches from a single dashboard.`,
    },
    {
      question: "Is salonboost Free Salon management software?",
      answer: `No, We have free trial and our cost is best in the industry for the value we provide.`,
    },
  ];

  const securityFaqData = [
    {
      question: "Is SalonBoost salon management software secure?",
      answer: `Yes, SalonBoost uses advanced encryption, secure data storage, and regular security audits to ensure maximum protection of salon and client data.`,
    },
    {
      question: "How does SalonBoost protect customer data?",
      answer: `SalonBoost follows strict data privacy policies and uses SSL encryption to protect customer data from breaches.`,
    },
    {
      question: "Does SalonBoost comply with data protection laws?",
      answer: `Yes, SalonBoost complies with industry data protection regulations, ensuring secure handling of customer information.`,
    },
    {
      question: "Can I control access levels for my salon staff?",
      answer: `Yes, SalonBoost allows role-based access control, so you can set permissions for different staff members.`,
    },
    {
      question: "How often is SalonBoost data backed up?",
      answer: `SalonBoost performs automatic daily backups to prevent data loss and ensure business continuity.`,
    },
    {
      question: "What happens if there is a system failure?",
      answer: `SalonBoost has a disaster recovery system in place, ensuring your data remains safe even in the event of technical failures.`,
    },
  ];

  return (
    <>
      <section
        id="faq"
        className="mt-4"
        style={{ backgroundColor: "#fff",padding: "20px" }}
      >
        <h2 style={{ textAlign:"center",color: "#343a40" }}>
          Common Questions
        </h2>
        <p style={{ textAlign:"center",color: "#6c757d" }}>
          Got questions? We’re here to assist you!
        </p>
        <CRow
          className="p-lg-5 w-lg-75 m-auto mt-4"
          style={{ margin: "0 auto", maxWidth: "1200px" }}
        >
          <CCol sm={12} lg={6} className="p-3">
            <h4 style={{ paddingLeft: "20px", color: "#343a40" }}>
              General Questions
            </h4>
            <CAccordion>
              {generalFaqData.map((faq, index) => (
                <CAccordionItem key={index} itemKey={index}>
                  <CAccordionHeader>{faq.question}</CAccordionHeader>
                  <CAccordionBody>{faq.answer}</CAccordionBody>
                </CAccordionItem>
              ))}
            </CAccordion>
          </CCol>
          <CCol sm={12} lg={6} className="p-3 mt-4 mt-lg-0">
            <h4 style={{ paddingLeft: "20px", color: "#343a40" }}>
              Data Safety Questions
            </h4>
            <CAccordion>
              {securityFaqData.map((faq, index) => (
                <CAccordionItem key={index} itemKey={index}>
                  <CAccordionHeader>{faq.question}</CAccordionHeader>
                  <CAccordionBody>{faq.answer}</CAccordionBody>
                </CAccordionItem>
              ))}
            </CAccordion>
          </CCol>
        </CRow>
      </section>
    </>
  );
};

export default Faqs;
