import React, { useEffect, useState } from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import { cilLockLocked, cilPen } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import avatar8 from "../assets/images/avatars/dropdownLogo.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiCall } from "../helper/api";

const baseURL = process.env.REACT_APP_BASE_URL;

const AppHeaderDropdown = () => {
  const navigate = useNavigate();
  const userdata = JSON.parse(localStorage.getItem("userdata"));
  const token = userdata ? userdata.data.token : null;
  const { data } = JSON.parse(localStorage.getItem("userdata"));
  const [profileImage, setProfileImage] = useState(null);
  const logout = async () => {
    localStorage.removeItem("userdata");
    navigate("/", { replace: true });
  };


  useEffect(() => {
    const getProfile = async () => {
      try {
        const response = await apiCall(`${baseURL}/profile/getProfile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.error) {
          console.log(response.error);
        }
        const { data } = response;
        setProfileImage(data.profileImage ? filePathToUrl(data.profileImage) : avatar8);
      } catch (error) {
        console.error("Error getting data:", error);
      }
    };
    getProfile();
  }, []);

  function filePathToUrl(filePath) {
    const imageBaseUrl = baseURL;
    const imagePath = filePath
      .replace(/\\/g, "/")
      .split("/")
      .slice(1)
      .join("/");
    return `${imageBaseUrl}/static/${imagePath}`;
  }

  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
        <CAvatar
          src={profileImage ? profileImage : avatar8}
          size="lg"
          shape="rounded-0"
        />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0 pb-0" placement="bottom-end">
        <h6 className="pt-2 px-4 justify-content-center">
          Hi {data.salonName}
        </h6>
        <CDropdownDivider />
        <CDropdownItem
          className="pb-2"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/salon-profile")}
        >
          <CIcon icon={cilPen} className="me-2" />
          Salon Profile
        </CDropdownItem>
        <CDropdownItem href="*" onClick={() => logout()} className="pb-2">
          <CIcon icon={cilLockLocked} className="me-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
