import React from "react";
import CIcon from "@coreui/icons-react";
import {
  cilUser,
  cilSpeedometer,
  cilCalendar,
  cilInput,
  cilMoney,
  cilList,
  cilCommentSquare,
  cilPeople,
  cilInfo,
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";

const CustomIconComponent = ({ icon }) => {
  return (
    <CIcon
      icon={icon}
      customClassName="nav-icon"
      style={{
        flex: "0 0 3rem",
      }}
    />
  );
};

const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CustomIconComponent icon={cilSpeedometer} />,
  },
  {
    component: CNavTitle,
    name: "Billing",
  },
  {
    component: CNavItem,
    name: "Quick Sale",
    to: "/quicksale",
    icon: <CustomIconComponent icon={cilInput} />,
  },
  {
    component: CNavItem,
    name: "Appointment",
    to: "/appointment",
    icon: <CustomIconComponent icon={cilCalendar} />,
  },
  {
    component: CNavGroup,
    name: "Customers",
    icon: <CustomIconComponent icon={cilPeople} />,
    items: [
      {
        component: CNavItem,
        name: "Customer List",
        to: "/customers",
        icon: <CIcon icon={cilPeople} customClassName="nav-icon" />,
      },
      {
        component: CNavItem,
        name: "Feedback",
        to: "/customer-feedback",
        icon: <CIcon icon={cilCommentSquare} customClassName="nav-icon" />,
      },
    ],
  },
  {
    component: CNavTitle,
    name: "Analytics",
  },
  {
    component: CNavItem,
    name: "Report",
    to: "/report/appointments",
    icon: <CustomIconComponent icon={cilInfo} />,
  },
  {
    component: CNavTitle,
    name: "Master",
  },
  {
    component: CNavGroup,
    name: "Salon Settings",
    to: "/offerings",
    icon: <CustomIconComponent icon={cilList} />,
    items: [
      {
        component: CNavItem,
        name: "Service",
        to: "/offerings/service",
      },
      {
        component: CNavItem,
        name: "Package",
        to: "/offerings/package",
      },
      {
        component: CNavItem,
        name: "Product",
        to: "/offerings/product",
      },
      {
        component: CNavItem,
        name: "Prepaid",
        to: "/offerings/prepaid",
      },
    ],
  },
  {
    component: CNavItem,
    name: "Staffs",
    to: "/staffs",
    icon: <CustomIconComponent icon={cilUser} />,
  },
  {
    component: CNavGroup,
    name: "Expense",
    to: "/expense",
    icon: <CustomIconComponent icon={cilMoney} />,
    items: [
      // {
      //   component: CNavItem,
      //   name: "Expense Category",
      //   to: "/expense/expense-category",
      // },
      {
        component: CNavItem,
        name: "Expense List",
        to: "/expense/expense-list",
      },
    ],
  },
];

export default _nav;
