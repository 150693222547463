import React, { useEffect } from "react";
import Footer from "./Footer";
import Faqs from "./Faqs";
import SecurityFeatures from "./SecurityFeatures";
import TopNavbar from "./Header/TopNavbar";
import { Link } from "react-router-dom";
import BottomHero from "./Header/BottomHero";
import { Helmet } from "react-helmet-async";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://salonboost.online/terms-and-conditions`} />
        <meta
          name="description"
          content="Manage your salon or spa with SalonBoost, the top salon management software. Free trial available. Track appointments, inventory, payments, and more."
        />
      </Helmet>
      <div className="montserrat_Font bg-white">
        <TopNavbar className="mb-4" />
        <BottomHero />
        <div className="mt-6 pt-5 w-lg-75 pb-5 m-auto">
          <h1 className="fs-1 mt-3 mb-4 mms-1">Terms and Conditions</h1>
          <div className="mms-1 pme-3">
            <h2>Introduction</h2>
            <p className="fs-5">
              Welcome to SalonBoost Software. These Terms and Conditions govern your use of our
              website <a href="https://salonboost.online">https://salonboost.online</a> and our
              salon management software. By using our services, you agree to comply with these
              terms. If you do not agree, please do not use our platform.
            </p>
            <h3>1. Definitions</h3>

            <ul className="fs-5">
              <li>
                "Company" / "We" / "Us" refers to SalonBoost Software, headquartered in Bangalore,
                India.
              </li>
              <li>"User" / "You" refers to anyone using our website or software.</li>
              <li>
                "Services" refers to all features offered by SalonBoost, including appointment
                booking, POS system, customer management, and other salon business tools.
              </li>
            </ul>
            <h3>2. Account & Subscription</h3>
            <h4 className="ps-3">1. User Registration</h4>
            <ul className="fs-5">
              <li>
                To access SalonBoost Software, you must create an account and provide accurate
                information.
              </li>
              <li>
                You are responsible for maintaining the confidentiality of your account and
                password.
              </li>
            </ul>
            <h4 className="ps-3">2. Subscription & Payments</h4>
            <ul className="fs-5">
              <li>Some features require a paid subscription.</li>
              <li>
                By subscribing, you authorize us to charge your payment method on a recurring basis.
              </li>
              <li>
                Subscription fees are non-refundable except as per our{" "}
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
            </ul>
            <h3>3. Acceptable Use Policy</h3>
            <ul className="fs-5">
              <li>You must use SalonBoost only for lawful salon business operations.</li>
              <li>
                You must not use our services to:
                <ul>
                  <li>Engage in fraudulent or illegal activities.</li>
                  <li>Upload harmful, offensive, or copyrighted content without permission.</li>
                  <li>Disrupt our servers or attempt to hack the system.</li>
                </ul>
              </li>
            </ul>
            <h3>4. Service Availability</h3>
            <ul className="fs-5">
              <li>We strive for 99.9% uptime, but we do not guarantee uninterrupted service.</li>
              <li>We may modify or discontinue any feature without prior notice.</li>
            </ul>
            <h3>5. Data Privacy</h3>
            <ul className="fs-5">
              <li>
                We collect and process your data as outlined in our{" "}
                <Link to="/">Privacy Policy</Link>
              </li>
              <li>
                We do not sell or share your personal information with third parties without
                consent.
              </li>
            </ul>
            <h3>6. Limitation of Liability</h3>
            <ul className="fs-5">
              <li>SalonBoost Software is provided "as is" without warranties of any kind.</li>
              <li>
                We are not responsible for any loss of data, revenue, or business disruptions caused
                by software errors or external factors.
              </li>
              <li>
                Our maximum liability is limited to the amount paid by the user in the last 3
                months.
              </li>
            </ul>
            <h3>7. Termination of Service</h3>
            <ul className="fs-5">
              <li>
                We reserve the right to suspend or terminate accounts that violate these terms.
              </li>
              <li>
                Users can cancel their subscription at any time, but no refunds will be provided.
              </li>
            </ul>
            <h3>8. Changes to Terms</h3>
            <ul className="fs-5">
              <li>We may update these Terms and Conditions from time to time.</li>
              <li>
                Continued use of SalonBoost after any changes means you accept the updated terms.
              </li>
            </ul>
            <h3>9. Contact Us</h3>
            <p className="fs-5 ps-3">If you have any questions, reach out to us:</p>
            <ul className=" list-unstyled fs-5 ps-3">
              <li>
                📧 <span className="fw-bolder">Email:</span>{" "}
                <a href="mailto:info@salonboost.online">info@salonboost.online</a>
              </li>
              <li>
                📞 <span className="fw-bolder">Phone:</span>{" "}
                <a href="tel:+917090515255">+91 70905 15255</a>
              </li>
              <li>
                📲 <span className="fw-bolder">WhatsApp:</span>{" "}
                <a href="https://wa.me/917090515255">Chat Now</a>
              </li>
            </ul>

            <p className="fs-5 ps-3">
              🔹 By using SalonBoost, you agree to these Terms and Conditions. Thank you for
              choosing the best salon software in India!
            </p>
          </div>
        </div>
        <Faqs />
        <SecurityFeatures />
        <Footer showTrustpilot={false} />
      </div>
    </>
  );
};

export default TermsAndConditions;
