import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from "@coreui/react";
import { AppSidebarNav } from "./AppSidebarNav";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import navigation from "./_nav";

const AppSidebar = () => {
  const dispatch = useDispatch();
  const unfoldable = useSelector((state) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state) => state.sidebarShow);
  const sidebarOccupyStart = unfoldable ? "4rem" : "12rem";

  return (
    <>
      <style>
        {`
          @media (min-width: 768px) {
            .sidebar.sidebar-sm:not(.sidebar-end):not(.hide) ~ * {
              --cui-sidebar-occupy-start: ${sidebarOccupyStart};
            }
          }
        `}
      </style>
      <CSidebar
        size="sm"
        style={{ backgroundColor: "#4B1AD1" }}
        position="fixed"
        unfoldable={unfoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch({ type: "set", sidebarShow: visible });
        }}
      >
        <CSidebarBrand className="d-none d-md-flex" to="/">
          <CImage
            fluid
            src="/images/logo.svg"
            alt="SalonBoost Logo"
            style={{ paddingTop: "2px", transform: "scale(1.3)" }}
          />
        </CSidebarBrand>
        <CSidebarNav>
          <SimpleBar>
            <AppSidebarNav items={navigation} />
          </SimpleBar>
        </CSidebarNav>
        {/* <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: "set", sidebarUnfoldable: !unfoldable })}
      /> */}
      </CSidebar>
    </>
  );
};

export default React.memo(AppSidebar);
