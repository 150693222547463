import React from "react";
import { CRow, CCol, CCardHeader, CCard, CCardBody, CImage, CButton } from "@coreui/react";
import landing3 from "../../assets/images/landing/3.PNG";
const LandingPricing = () => {
  const phoneNumber = "+917090515255";
  const message = "From Website - I am interested in salonboost offer";
  const openWhatsApp = () => {
    const whatsappLink = `https://wa.me/${phoneNumber.replace(
      /[-+ ]/g,
      ""
    )}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <div
      style={{
        backgroundColor: "rgb(235, 235, 255)",
      }}
      id="pricing"
    >
      <CRow className="fs-5 w-lg-75 m-auto p-lg-5 justify-content-center">
        <CCol sm={12}>
          <CRow className="p-2">
            <h1 className="fs-1 mmt-2 ">
              We Challenge You to{" "}
              <span
                style={{
                  backgroundColor: "red",
                  color: "white",
                  padding: "2px 5px",
                  display: "inline-block",
                }}
              >
                Find a Better Deal!
              </span>
            </h1>
            <p className="mt-lg-5 fs-2  mmb-0">
              SalonBoost offers the{" "}
              <span className="fw-bolder fs-1">Best price in the industry</span> with{" "}
              <span className="fw-bolder">free WhatsApp API for transaction messages.</span> No one
              can beat our value!
            </p>
          </CRow>

          <CRow
            className="mt-5 mmb-0"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              backgroundColor: "#f5f5f5",
              padding: "20px",
              borderRadius: "8px",
              flexWrap: "wrap", // Ensures proper alignment on small screens
            }}
          >
            <CCol xs={12} md={2}>
              <CImage
                src={landing3}
                alt="Landing Image"
                style={{
                  width: "150px",
                  height: "150px",
                  display: "block",
                  margin: "auto", // Center image on small screens
                }}
              />
            </CCol>

            <CCol xs={12} md={9} className="p-4 ps-md-5">
              <h3 className="mb-2 mmt-2">SalonBoost's Risk-Free Guarantee!</h3>
              <p>
                Start with just ₹999/- and enjoy a 15-day risk-free trial. If you're not happy with
                the software, we'll refund your ₹999/- in full. No hassles, no hidden conditions.
              </p>
            </CCol>
          </CRow>

          <CRow className="p-2">
            <p className="fs-3 mt-2">
              We're confident that SalonBoost will help your salon grow, and we're here to back you
              every step of the way!
            </p>
          </CRow>
        </CCol>

        <CRow className="p-lg-5 ">
          <CCol sm={6}>
            <CCard className="shadow-sm text-center mmt-3">
              <CCardHeader className="bg-white position-relative">
                <h5 className="fw-bold ">Standard</h5>
              </CCardHeader>
              <CCardBody>
                <h6>
                  Perfect for salons with 4 or more staff. Optimized for streamlined operations and
                  essential features.
                </h6>
                <h2 className="fw-bold">
                  ₹1499<small className="text-muted">/monthly</small>
                </h2>
                <p className="text-muted">₹14388/year (20% off)</p>
                <p className="text-muted">No Setup Fee Required</p>
                <CButton
                  color="danger"
                  className="text-white px-4"
                  onClick={() => {
                    openWhatsApp();
                  }}
                >
                  Get Started
                </CButton>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol sm={6}>
            <CCard className="shadow-sm text-center mmt-3">
              <CCardHeader className="bg-white">
                <h5 className="fw-bold">Premium</h5>
                <span
                  className="position-absolute top-0 start-0 translate-middle badge bg-danger text-white"
                  style={{ marginLeft: "82px" }}
                >
                  Most Popular
                </span>
              </CCardHeader>
              <CCardBody>
                <h6>
                  Get full access to WhatsApp transaction messages, automated reminders, and more to
                  boost efficiency and save time. Unlock all premium features to scale your business
                  effortlessly.
                </h6>
                <h2 className="fw-bold">
                  ₹2500<small className="text-muted">/monthly</small>
                </h2>

                <p className="text-muted">₹24000/year (20% off)</p>
                <p className="text-muted">No Setup Fee Required</p>
                <CButton
                  color="danger"
                  className="text-white px-4"
                  onClick={() => {
                    openWhatsApp();
                  }}
                >
                  Get Started
                </CButton>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CRow>
    </div>
  );
};

export default LandingPricing;
