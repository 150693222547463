import React, { useEffect } from "react";
import Footer from "./Footer";
import Faqs from "./Faqs";
import SecurityFeatures from "./SecurityFeatures";
import TopNavbar from "./Header/TopNavbar";
import BottomHero from "./Header/BottomHero";
import { Helmet } from "react-helmet-async";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://salonboost.online/refund-policy`} />
        <meta
          name="description"
          content="Manage your salon or spa with SalonBoost, the top salon management software. Free trial available. Track appointments, inventory, payments, and more."
        />
      </Helmet>
      <div className="montserrat_Font bg-white">
        <TopNavbar className="mb-4" />
        <BottomHero />
        <div className="mt-6 pt-5 w-lg-75 pb-5 m-auto">
          <h1 className="fs-1 mt-3 mb-4 mms-1">Refund Policy</h1>
          <div className="mms-1 pme-3">
            <h2>SalonBoost Refund Policy</h2>
            <p className="fs-5">
              At SalonBoost Software, we strive to provide the best salon management software for
              businesses of all sizes. We want our customers to be fully satisfied with our
              services. However, if you are not satisfied with your purchase, please review our
              refund policy below.
            </p>

            <h3>Eligibility for Refund</h3>
            <p className="fs-5">We offer refunds only under the following conditions:</p>
            <ul className="fs-5">
              <li>
                <span className="fw-bolder">Billing Errors</span> – If you have been charged
                incorrectly or for a service you did not opt for, you are eligible for a full
                refund.
              </li>
              <li>
                <span className="fw-bolder">Technical Issues</span> – If SalonBoost fails to
                function due to a critical technical issue (verified by our support team) and we are
                unable to resolve it within 7 business days, a refund may be considered.
              </li>
              <li>
                <span className="fw-bolder">Duplicate Payments</span> – If a customer has been
                charged twice for the same service, the duplicate amount will be refunded.
              </li>
            </ul>

            <h3>Non-Refundable Cases</h3>
            <p className="fs-5">Refunds will not be provided in the following cases:</p>
            <ul className="list-unstyled fs-5 ps-3">
              <li>
                ❌ <span className="fw-bolder">Change of Mind</span> – If you decide to stop using
                SalonBoost after purchase.
              </li>
              <li>
                ❌ <span className="fw-bolder">Lack of Usage</span> – If you did not use the
                software but did not cancel the subscription.
              </li>
              <li>
                ❌<span className="fw-bolder">Trial Periods</span> – Payments made for free trial
                upgrades or promotional plans are non-refundable.
              </li>
              <li>
                ❌<span className="fw-bolder">Third-Party Fees</span> – Any additional fees paid to
                third-party services (such as payment gateways, SMS providers, or marketing tools)
                are not eligible for refunds.
              </li>
            </ul>

            <h3>Refund Process</h3>
            <p className="fs-5">To request a refund, please follow these steps:</p>
            <ol className="fs-5">
              <li>
                Email us at <a href="mailto:info@salonboost.online">info@salonboost.online</a> with
                your order details and reason for refund.
              </li>
              <li>Our team will review your request within 5-7 business days.</li>
              <li>
                If eligible, the refund will be processed to your original payment method within
                7-10 business days.
              </li>
            </ol>

            <h3>Contact Us</h3>
            <p className="fs-5">
              If you have any questions regarding our refund policy, feel free to reach out:
            </p>
            <ul className=" list-unstyled fs-5 ps-3">
              <li>
                📧 <span className="fw-bolder">Email:</span>{" "}
                <a href="mailto:info@salonboost.online">info@salonboost.online</a>
              </li>
              <li>
                📞 <span className="fw-bolder">Phone:</span>{" "}
                <a href="tel:+917090515255">+91 70905 15255</a>
              </li>
              <li>
                📲 <span className="fw-bolder">WhatsApp:</span>{" "}
                <a href="https://wa.me/917090515255">Chat Now</a>
              </li>
            </ul>

            <p className="fs-5 ps-3">
              🔹 SalonBoost is committed to providing the best salon software in India. If you have
              any concerns, we are here to help!
            </p>
          </div>
        </div>
        <Faqs />
        <SecurityFeatures />
        <Footer showTrustpilot={false} />
      </div>
    </>
  );
};

export default RefundPolicy;
