import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser, cilEnvelopeOpen, cilPhone, cilText } from "@coreui/icons";
import * as Styled from "./Style";
import { useToastContext } from "../../../layout/ToastContext";
import { apiCall } from "../../../helper/api";
const baseURL = process.env.REACT_APP_BASE_URL;

const Register = () => {
  const { showToast } = useToastContext();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errorData, setErrorData] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorData("");
    let validationFailed = false;
    if (!password) {
      setErrorData("Enter password");
      validationFailed = true;
    }
    if (!mobile) {
      setErrorData("Enter your mobile number");
      validationFailed = true;
    }
    if (!countryCode) {
      setErrorData("Enter your countryCode");
      validationFailed = true;
    }
    if (!businessName) {
      setErrorData("Enter your business name");
      validationFailed = true;
    }
    if (!email) {
      setErrorData("Enter email address");
      validationFailed = true;
    }
    if (!name) {
      setErrorData("Enter full name");
      validationFailed = true;
    }

    if (validationFailed) {
      return;
    }
    const lowercaseEmail = email.toLowerCase();
    setIsLoading(true);
    try {
      const response = await apiCall(`${baseURL}/auth/register`, {
        method: "POST",
        body: JSON.stringify({
          name,
          email: lowercaseEmail,
          businessName,
          countryCode,
          mobile,
          password,
        }),
      });

      if (response.error) {
        showToast(response.error, "danger", { autohide: 5000 });
        return;
      }

      showToast(response.message, "primary", { autohide: 3000 });
      navigate("/otpverify", { state: { email } });

      // window.location.reload();
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Sign Up</h1>
                    <p className="text-medium-emphasis">Sign In to your account</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="name"
                        autoComplete="name"
                        placeholder="Enter full name"
                        id="name"
                        value={name}
                        required
                        onChange={(e) => setName(e.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilEnvelopeOpen} />
                      </CInputGroupText>
                      <CFormInput
                        type="email"
                        placeholder="Enter email address"
                        autoComplete="email"
                        id="email"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilText} />
                      </CInputGroupText>
                      <CFormInput
                        type="text"
                        autoComplete="businessname"
                        placeholder="Enter your business name"
                        id="businessName"
                        value={businessName}
                        required
                        onChange={(e) => setBusinessName(e.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-3" style={{ display: "flex" }}>
                      <CInputGroupText>
                        <CIcon icon={cilPhone} />
                      </CInputGroupText>
                      <CFormInput
                        type="tel"
                        placeholder="+91"
                        autoComplete="countryCode"
                        id="countryCode"
                        readOnly
                        value={countryCode}
                        onChange={(e) => setCountryCode(e.target.value)}
                        style={{ flex: "0 0 17%", marginRight: "0.5px" }}
                      />
                      <CFormInput
                        type="number"
                        placeholder="Enter your mobile number"
                        autoComplete="mobile"
                        id="mobile"
                        value={mobile}
                        required
                        onChange={(e) => setMobile(e.target.value)}
                        style={{ flex: "1" }}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Enter password"
                        autoComplete="current-password"
                        id="password"
                        value={password}
                        required
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </CInputGroup>
                    {errorData && (
                      <div style={{ color: "red", paddingBottom: "10px" }}>{errorData}</div>
                    )}
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" onClick={handleSubmit}>
                          {isLoading ? (
                            <>
                              <Styled.LoadingIcon />
                            </>
                          ) : (
                            `Sign Up`
                          )}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5">
                <CCardBody className="text-center">
                  <div>
                    <h2>Login</h2>
                    <p>
                      Once you've registered, you can log in using your email address and password.
                      Access your account by logging in.
                    </p>
                    <Link to="/login">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>
                        Login Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Register;
