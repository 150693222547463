import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as Styled from "../register/Style";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser, cilLockLocked } from "@coreui/icons";
import { useToastContext } from "../../../layout/ToastContext";
const baseURL = process.env.REACT_APP_BASE_URL;

const Reset = () => {
  const { showToast } = useToastContext();
  const [newPassword, setNewPassword] = useState("");
  const [otp, setOtp] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorData, setErrorData] = useState("");
  const navigate = useNavigate();
  const token = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorData("");
    setIsLoading(true);
    console.log(token.token, otp);
    try {
      const response = await fetch(
        `${baseURL}/auth/forgot/reset/${token.token}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ otp, newPassword }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        showToast(data.message, "primary", { autohide: 3000 });
        navigate("/login");
      } else {
        const errorData = await response.json();
        showToast(errorData.error, "danger", { autohide: 5000 });
      }
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Reset Password</h1>
                    <p className="text-medium-emphasis">
                      Enter OTP and Password
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        type="text"
                        placeholder="Enter OTP"
                        autoComplete="otp"
                        id="otp"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder=" Enter new password"
                        autoComplete="current-password"
                        id="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </CInputGroup>
                    {errorData && (
                      <div className="alert alert-danger" role="alert">
                        {errorData.error}
                      </div>
                    )}
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          color="primary"
                          className="px-4"
                          onClick={handleSubmit}
                        >
                          {isLoading ? (
                            <>
                              <Styled.LoadingIcon />
                            </>
                          ) : (
                            `Submit`
                          )}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-5"
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>Login</h2>
                    <p>
                      Let’s create your account Sign up with Salonboost and get
                      Free Trial for 14 Days. No credit card required. No
                      obligations or fixed-term contracts.
                    </p>
                    <Link to="/login">
                      <CButton
                        color="primary"
                        className="mt-3"
                        active
                        tabIndex={-1}
                      >
                        Login Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Reset;
