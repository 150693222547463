import { Route, Routes, useLocation } from "react-router-dom";
import "./assets/scss/style.scss";
import { ToastProvider } from "./layout/ToastContext";
import Home from "./views/landingPage/Home";
import VDPPage from "./views/landingPage/VDPPage";
import DefaultLayout from "./layout/DefaultLayout";
import AppointmentPrint from "./views/pages/components/AppointmentPrint";
import Login from "./views/auth/login/Login";
import Register from "./views/auth/register/Register";
import Forgot from "./views/auth/forgot/Forgot";
import OtpVerify from "./views/auth/otpverify/OtpVerify";
import Reset from "./views/auth/reset/Reset";
import FeedbackPage from "./views/pages/feedback/FeedbackPage";
import WhySalonboost from "./views/landingPage/whysalonboost/WhySalonboost";
import Pricing from "./views/landingPage/Pricing/Pricing";
import Dummy from "./views/landingPage/Dummy";
import AboutUs from "./views/landingPage/about/AboutUs";
import ContactUs from "./views/landingPage/contact/ContactUs";
import RefundPolicy from "./views/landingPage/RefundPolicy";
import TermsAndConditions from "./views/landingPage/TermsAndConditions";

function App() {
  const location = useLocation();
  const auth = !!localStorage.getItem("userdata") || !!location.state?.data;

  return (
    <div className="App">
      <ToastProvider>
        {auth ? (
          <Routes>
            <Route path="*" element={<DefaultLayout />} />
            <Route path="/print" element={<AppointmentPrint />} />
            <Route path="/feedback/:id" element={<FeedbackPage />} />
          </Routes>
        ) : (
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot" element={<Forgot />} />
            <Route path="/otpverify" element={<OtpVerify />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/forgot/reset/:token" element={<Reset />} />
            <Route path="/feedback/:id" element={<FeedbackPage />} />
            <Route path="/*" element={<Home />} />
            <Route path="/compare-salon-softwares" element={<WhySalonboost />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/blog" element={<Dummy route={"blog"} />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/leadership" element={<Dummy route={"leadership"} />} />
            <Route path="/security" element={<VDPPage />} />
          </Routes>
        )}
      </ToastProvider>
    </div>
  );
}

export default App;
