import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useToastContext } from "../../../layout/ToastContext";
import { CContainer, CRow, CCol, CCard } from "@coreui/react";
import { apiCall } from "../../../helper/api";
import terribleSvg from "./svgs/terrible.svg";
import badSvg from "./svgs/bad.svg";
import okSvg from "./svgs/okay.svg";
import goodSvg from "./svgs/good.svg";
import amazingSvg from "./svgs/amazing.svg";

const baseURL = process.env.REACT_APP_BASE_URL;

const FeedbackPage = () => {
  const params = useParams();
  const { showToast } = useToastContext();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  // const [hoverValue, setHoverValue] = useState(undefined);
  const [salonData, setSalonData] = useState({});
  const [comment, setComment] = useState("");
  const mobileScreen = window.innerWidth < 576;

  const ratings = [
    {
      id: 1,
      svg: terribleSvg,
      text: "Terrible",
    },
    {
      id: 2,
      svg: badSvg,
      text: "Bad",
    },
    {
      id: 3,
      svg: okSvg,
      text: "Okay",
    },
    {
      id: 4,
      svg: goodSvg,
      text: "Good",
    },
    {
      id: 5,
      svg: amazingSvg,
      text: "Amazing",
    },
  ];

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const response = await fetch(`${baseURL}/feedback/${params.id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const { data } = await response.json();
          setSalonData(data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (params.id) {
      fetchDataAsync();
    }
  }, [params.id]);

  const handleClick = (value) => {
    setCurrentValue(value);
    if (value > 3) {
      const googleReviewLink = salonData.salonFeedback;
      window.open(googleReviewLink, "_blank");
      setCurrentValue(0);
    } else {
      return;
    }
  };

  const handleSubmit = async () => {
    const feedbackData = {
      rating: currentValue,
      salonId: params.id.split("-")[0],
      customerId: params.id.split("-")[1],
      comment,
    };
    console.log(feedbackData);
    try {
      const response = await apiCall(`${baseURL}/feedback/feedbackCreate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(feedbackData),
      });
      if (response.error) {
        console.log(response.error);
        showToast(response.error, "danger");
        return;
      }
      const { message } = response
      setIsSubmitted(true);
      showToast(message, "primary");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isSubmitted ? (
        <div className="min-vh-100 d-flex flex-row align-items-center" style={styles.wrapper}>
          <h1 style={{...styles.container, justifyContent: "center", width: "100vw", marginTop: "10vh"}}>
            Thank you for your feedback!
          </h1>
        </div>
      ) : (
        <div className="min-vh-100 d-flex flex-row align-items-center" style={styles.wrapper}>
          <CContainer>
            <CRow className="justify-content-center p-0">
              <CCol xxl={5} xl={7} lg={8} md={8} sm={12} style={styles.container}>
                <div
                  style={{ ...styles.shadowDiv, display: mobileScreen ? "none" : "block" }}
                ></div>
                <div
                  style={{
                    ...styles.forwardDiv,
                    width: mobileScreen ? "100%" : "90%",
                    height: mobileScreen ? "100%" : "85%",
                  }}
                >
                  <CRow className={`justify-content-center ${mobileScreen ? "p-3" : "p-5"}`}>
                    <CCol sm={12} style={styles.title} className="mb-2">
                      Give Feedback
                    </CCol>
                    <CCol sm={12} className="mb-2">
                      What do you think about <strong>{salonData.businessName}?</strong>
                    </CCol>
                    <CCol sm={12} className="mmt-1 mmb-1 p-0">
                      <CRow
                        className="px-3"
                        sm={{ cols: 5, gutter: 5 }}
                        md={{ cols: 5, gutter: 5 }}
                        lg={{ cols: 5, gutter: 5 }}
                        xl={{ cols: 5, gutter: 5 }}
                        xxl={{ cols: 5, gutter: 5 }}
                      >
                        {ratings.map((rating, index) => {
                          return (
                            <>
                              {mobileScreen ? (
                                <>
                                  <CCol
                                    sm={2}
                                    className="p-0"
                                    onClick={() => {
                                      handleClick(index + 1);
                                    }}
                                    style={{
                                      width: index === 0 || index === 4 ? "17%" : "20%",
                                    }}
                                  >
                                    <CRow
                                      className="justify-content-center align-items-center"
                                      style={styles.cardRow}
                                    >
                                      <CCol
                                        sm={12}
                                        className="justify-content-center d-block text-center"
                                      >
                                        <img
                                          src={rating.svg}
                                          alt={rating.text}
                                          style={currentValue === index + 1 ? styles.svgImage : {}}
                                        />
                                      </CCol>
                                      <CCol sm={12} className="text-center">
                                        {rating.text}
                                      </CCol>
                                    </CRow>
                                  </CCol>
                                </>
                              ) : (
                                <CCol sm={2} className="p-0">
                                  <CCard
                                    style={styles.cardStyle}
                                    onClick={() => {
                                      handleClick(index + 1);
                                    }}
                                  >
                                    <CRow
                                      className="justify-content-center align-items-center"
                                      style={styles.cardRow}
                                    >
                                      <CCol
                                        sm={12}
                                        className="justify-content-center d-block text-center"
                                      >
                                        <img
                                          src={rating.svg}
                                          alt={rating.text}
                                          style={currentValue === index + 1 ? styles.svgImage : {}}
                                        />
                                      </CCol>
                                      <CCol sm={12} className="text-center">
                                        {rating.text}
                                      </CCol>
                                    </CRow>
                                  </CCard>
                                </CCol>
                              )}
                            </>
                          );
                        })}
                      </CRow>
                    </CCol>
                    <CCol sm={12} className="mt-2">
                      <p style={styles.desc}>Please share your valuable feedback.</p>
                    </CCol>
                    <CCol sm={12} className="mb-4">
                      <textarea
                        placeholder="How was your experience?"
                        style={styles.textarea}
                        name="comment"
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </CCol>
                    <CCol sm={12}>
                      <button style={styles.button} onClick={handleSubmit}>
                        Submit
                      </button>
                    </CCol>
                  </CRow>
                </div>
              </CCol>
            </CRow>
          </CContainer>
        </div>
      )}
    </>
  );
};

const styles = {
  wrapper: {
    backgroundColor: "#F2F6FF",
  },
  container: {
    minHeight: "650px",
    display: "flex",
    padding: 0,
    backgroundColor: "#F2F6FF",
    position: "relative",
  },
  shadowDiv: {
    position: "absolute",
    borderRadius: 20,
    top: 0,
    left: 0,
    width: "90%",
    height: "80%",
    backgroundColor: "#E0E9FE",
    zIndex: 2,
  },
  forwardDiv: {
    position: "absolute",
    borderRadius: 20,
    boxShadow: "10px 10px 25px #7f56f0bd",
    bottom: 0,
    right: 0,
    width: "90%",
    height: "85%",
    backgroundColor: "#F8FAFF",
    zIndex: 3,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#121C51",
  },
  desc: {
    fontSize: 16,
    fontWeight: "normal",
    color: "#121C51",
  },
  cardStyle: {
    width: "100px",
    height: "100px",
    backgroundColor: "#F8FAFF",
    border: "none",
    display: "block",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // cursor: "pointer",
    margin: "13px 0px",
  },
  cardRow: {
    cursor: "pointer !important",
    borderRadius: 10,
    backgroundColor: "#F8FAFF",
    zIndex: 4,
  },
  svgImage: {
    cursor: "pointer",
    background:
      "radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(226,236,242,1) 62%, rgba(212,206,238,1) 87%, rgba(169,190,245,1) 100%)",
    transform: "scale(1.2)",
    borderRadius: 40,
    objectFit: "cover",
  },
  textarea: {
    border: "1px solid #916ef1cf",
    borderRadius: 5,
    padding: 10,
    minHeight: 100,
    width: "100%",
  },
  button: {
    border: "1px solid #916ef1cf",
    borderRadius: 5,
    width: "100%",
    padding: 10,
    backgroundColor: "#f8f5ffba",
  },
};

// {
//   /* <div className="wrapper min-vh-100" style={styles.wrapper}>
//         {isSubmitted ? (
//           <h1 style={styles.container}>Thank you for your feedback!</h1>
//         ) : (
//           <>
//             <div style={styles.container}>
//               <h2> Salonboost Ratings </h2>
//               <div style={styles.stars}>
//                 {stars.map((_, index) => {
//                   return (
//                     <FaStar
//                       key={index}
//                       size={24}
//                       onClick={() => handleClick(index + 1)}
//                       onMouseOver={() => handleMouseOver(index + 1)}
//                       onMouseLeave={handleMouseLeave}
//                       color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
//                       style={{
//                         marginRight: 10,
//                         cursor: "pointer",
//                       }}
//                     />
//                   );
//                 })}
//               </div>
//               <textarea
//                 placeholder="What's your experience?"
//                 style={styles.textarea}
//                 name="comment"
//                 onChange={(e) => setComment(e.target.value)}
//               />

//               <button style={styles.button} onClick={handleSubmit}>
//                 Submit
//               </button>
//             </div>
//           </>
//         )}
//       </div> */
// }

// const styles1 = {
//   wrapper: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     backgroundColor: "#f2f6ff",
//   },
//   container: {
//     paddingTop: 100,
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//   },
//   stars: {
//     display: "flex",
//     gap: 15,
//     flexDirection: "row",
//   },
//   textarea: {
//     border: "1px solid #5a27e5",
//     borderRadius: 5,
//     padding: 10,
//     margin: "20px 0",
//     minHeight: 100,
//     width: 500,
//     minWidth: 300,
//   },
//   button: {
//     border: "1px solid #5a27e5",
//     borderRadius: 5,
//     width: 500,
//     minwidth: 300,
//     padding: 10,
//   },
// };

export default FeedbackPage;
