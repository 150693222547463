import React, { useEffect } from "react";
import Footer from "../Footer";
import Faqs from "../Faqs";
import SecurityFeatures from "../SecurityFeatures";
import TopNavbar from "../Header/TopNavbar";
import BottomHero from "../Header/BottomHero";
import { Helmet } from "react-helmet-async";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://salonboost.online/about-us`} />
        <meta
          name="description"
          content="Manage your salon or spa with SalonBoost, the top salon management software. Free trial available. Track appointments, inventory, payments, and more."
        />
      </Helmet>
      <div className="montserrat_Font bg-white">
        <TopNavbar className="mb-4" />
        <BottomHero />
        <div className="mt-6 pt-5 w-lg-75 pb-5 m-auto">
          <h1 className="fs-1 mt-3 mb-4 mms-1">About Us</h1>
          <div className="mms-1 pme-3">
            <h2>Empowering Salons with the Best Salon Software in India</h2>
            <p className="fs-5">
              At SalonBoost, we help salon owners manage bookings, increase customer satisfaction,
              and grow their business effortlessly. Our goal is to provide affordable and
              easy-to-use salon management software that simplifies daily operations.
            </p>
            <h3>Who We Are</h3>
            <p className="fs-5">
              SalonBoost is a product of SalonBoost Software, founded in 2024. Headquartered in
              Bangalore, India, we are a team of passionate professionals dedicated to helping
              salons, spas, and beauty businesses succeed.
            </p>
            <p className="fs-5">
              We started SalonBoost after realizing that many salon owners struggle with manual
              scheduling, no-show clients, and managing reviews. Most salon software solutions were
              either too complex or expensive. So, we built a simple and cost-effective salon
              software tailored for small salons, beauty spas, and independent stylists.
            </p>
            <h3>Our Mission</h3>
            <p className="fs-5">
              Our mission is to empower salon owners with the best salon software for small salons
              in India. We believe technology should be simple, affordable, and designed to help
              businesses grow.
            </p>
            <h3>Why Choose SalonBoost?</h3>
            <ul className="list-unstyled fs-5 ps-3">
              <li>
                ✅ <span className="fw-bolder">Easy-to-Use</span> – No technical knowledge required!
              </li>
              <li>
                ✅ <span className="fw-bolder">Salon POS Software</span> – Simplifies billing and
                payments.
              </li>
              <li>
                ✅ <span className="fw-bolder">Appointment Booking & Reminders</span> – Reduces
                no-shows and improves scheduling.
              </li>
              <li>
                ✅ <span className="fw-bolder">Customer & Review Management</span> – Helps you build
                a strong online reputation.
              </li>
              <li>
                ✅ <span className="fw-bolder">Cloud-Based & Secure</span> – Manage your salon from
                anywhere.
              </li>
              <li>
                ✅ <span className="fw-bolder">Affordable & Free Plans</span> – Get started with
                salon management software free!
              </li>
            </ul>
            <h3>Company Details</h3>
            <ul className=" list-unstyled fs-5 ps-3">
              <li>
                📍 <span className="fw-bolder">Headquarters:</span> Bangalore, India
              </li>
              <li>
                📅 <span className="fw-bolder">Founded:</span> 2024
              </li>
              <li>
                📧 <span className="fw-bolder">Email:</span>{" "}
                <a href="mailto:info@salonboost.online">info@salonboost.online</a>
              </li>
              <li>
                🌐 <span className="fw-bolder">Website:</span>{" "}
                <a href="https://salonboost.online">https://salonboost.online</a>
              </li>
            </ul>
            <h3>Looking for the Best Salon Software 2025? Try SalonBoost!</h3>
            <p className="fs-5">
              We are constantly improving our platform to help salons stay ahead. If you're
              searching for free salon software in India, SalonBoost is your perfect solution.
            </p>

            <p className="fs-5">
              🔹 Ready to Boost Your Salon? Sign up today and take your business to the next level!
            </p>
          </div>
        </div>
        <Faqs />
        <SecurityFeatures />
        <Footer showTrustpilot={false} />
      </div>
    </>
  );
};

export default AboutUs;
