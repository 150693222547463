import React from "react";
const Dashboard = React.lazy(() => import("../views/pages/Dashboard"));
const SalonProfile = React.lazy(() => import("../views/pages/components/SalonProfile"));
const Customers = React.lazy(() => import("../views/pages/Customers"));
const FeedbackList = React.lazy(() => import("../views/pages/feedback/FeedbackList"));
const Services = React.lazy(() => import("../views/pages/Services"));
const Packages = React.lazy(() => import("../views/pages/Package"));
const Products = React.lazy(() => import("../views/pages/Products"));
const Prepaid = React.lazy(() => import("../views/pages/Prepaid"));
const Staff = React.lazy(() => import("../views/pages/Staff"));
const Appointment = React.lazy(() => import("../views/pages/Appointment"));
const Quicksale = React.lazy(() => import("../views/pages/Quicksale"));
const CustomerAppointments = React.lazy(() => import("../views/pages/CustomerAppointments"));
const Report = React.lazy(() => import("../views/pages/report/ReportPage"));
const Expense = React.lazy(() => import("../views/pages/expense/Expense"));
const ExpenseCategory = React.lazy(() => import("../views/pages/expense/ExpenseCategory"));
const CustomerAppointment = React.lazy(() =>
  import("../views/pages/components/CustomerAppointment")
);
const StaffAppointment = React.lazy(() => import("../views/pages/components/StaffAppointment"));
const ServiceWiseAppointments = React.lazy(() =>
  import("../views/pages/components/ServiceWiseAppointments")
);
const SourceWiseAppointments = React.lazy(() =>
  import("../views/pages/components/SourceWiseAppointments")
);

const routes = [
  { path: "/", exact: true, name: "Home" },
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    element: Dashboard,
  },
  {
    path: "/salon-profile",
    exact: true,
    name: "Salon Profile",
    element: SalonProfile,
  },
  { path: "/management", element: Customers, exact: true },
  {
    path: "/customers",
    name: "Customers",
    element: Customers,
  },
  {
    path: "/customer-feedback",
    name: "Feedback",
    element: FeedbackList,
  },
  {
    path: "/staffs",
    name: "Staffs",
    element: Staff,
  },
  { path: "/offerings", name: "Offerings", element: Services, exact: true },
  { path: "/offerings/service", name: "Service", element: Services },
  { path: "/offerings/package", name: "Package", element: Packages },
  { path: "/offerings/product", name: "Product", element: Products },
  { path: "/offerings/prepaid", name: "Prepaid", element: Prepaid },
  {
    path: "/appointment",
    exact: true,
    name: "Appointment",
    element: Appointment,
  },
  {
    path: "/quicksale",
    exact: true,
    name: "Quicksale",
    element: Quicksale,
  },
  {
    path: "/customer/appointments",
    exact: true,
    name: "Customer Appointments",
    element: CustomerAppointments,
  },
  {
    path: "/report/appointments",
    exact: true,
    name: "Report",
    element: Report,
  },
  { path: "/expense", name: "Expense", element: Expense, exact: true },
  // {
  //   path: "/expense/expense-category",
  //   name: "Expense Category",
  //   element: ExpenseCategory,
  // },
  { path: "/expense/expense-list", name: "Expense List", element: Expense },
  {
    path: "/customer/appointments/:id",
    name: "Customer Appointments",
    element: CustomerAppointment,
  },
  {
    path: "/staff/appointments/:id",
    name: "Staff Appointments",
    element: StaffAppointment,
  },
  {
    path: "/service/appointments/:id",
    name: "Service Wise Appointments",
    element: ServiceWiseAppointments,
  },
  {
    path: "/source/appointments",
    name: "Source Wise Appointments",
    element: SourceWiseAppointments,
  },
];

export default routes;
