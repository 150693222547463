import React, { useEffect } from "react";
import Footer from "../Footer";
import Faqs from "../Faqs";
import SecurityFeatures from "../SecurityFeatures";
import TopNavbar from "../Header/TopNavbar";
import BottomHero from "../Header/BottomHero";
import { Helmet } from "react-helmet-async";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const phoneNumber = "+917090515255";
  const message = "From Website - I am interested in salonboost offer";
  const openWhatsApp = () => {
    const whatsappLink = `https://wa.me/${phoneNumber.replace(
      /[-+ ]/g,
      ""
    )}?text=${encodeURIComponent(message)}`;
    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://salonboost.online/contact-us`} />
        <meta
          name="description"
          content="Manage your salon or spa with SalonBoost, the top salon management software. Free trial available. Track appointments, inventory, payments, and more."
        />
      </Helmet>
      <div className="montserrat_Font bg-white">
        <TopNavbar className="mb-4" />
        <BottomHero />

        <div className="mt-6 pt-5 w-lg-75 pb-5 m-auto">
          <h1 className="fs-1 mt-3 mb-4 mms-1">Contact Us</h1>
          <div className="mms-1 pme-3">
            <h2>Get in Touch with SalonBoost</h2>
            <p className="fs-5 ps-3">
              Have questions about SalonBoost, need support, or want to explore how our best salon
              software in India can help your business? We’re here to assist you!
            </p>

            <h3 className="mt-3 pb-2 ps-2">📍 Our Office</h3>
            <p className="fs-5 ps-5">
              SalonBoost – SalonBoost Software
              <br />
              Immadihalli Main Rd, Whitefield, Bengaluru, Karnataka 560066
            </p>

            <h3 className="mt-2 pb-2">📞 Contact Details</h3>
            <ul className="list-unstyled fs-5 ps-5">
              <li>
                📧 <span className="fw-bolder">Email:</span>{" "}
                <a href="mailto:info@salonboost.online">info@salonboost.online</a>
              </li>
              <li>
                📞 <span className="fw-bolder">Phone:</span>{" "}
                <a href="tel:+917090515255">+91 70905 15255</a>
              </li>
              <li>
                🌐 <span className="fw-bolder">Website:</span>{" "}
                <a href="https://salonboost.online">https://salonboost.online</a>
              </li>
            </ul>

            <h3 className="mt-2 pb-2">💬 Customer Support</h3>
            <p className="fs-5 ps-5">
              Need help with salon management software free trials, account setup, or
              troubleshooting? Contact our support team:
            </p>
            <ul className="list-unstyled fs-5 ps-5">
              <li>
                📩 <span className="fw-bolder">Support Email:</span>{" "}
                <a href="mailto:info@salonboost.online">info@salonboost.online</a>
              </li>
              <li>
                ⏰ <span className="fw-bolder">Support Hours:</span> 9 AM to 9 PM IST
              </li>
            </ul>

            <h3 className="mt-2 pb-2">📣 Follow Us</h3>
            <p className="fs-5 ps-5">Stay updated with the latest features, tips, and offers:</p>
            <ul className="list-unstyled fs-5 ps-5">
              <li>
                🔹 <span className="fw-bolder">Facebook:</span>{" "}
                <a
                  href="https://www.facebook.com/salonboost.software"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SalonBoost Facebook
                </a>
              </li>
              <li>
                🔹 <span className="fw-bolder">Instagram:</span>{" "}
                <a
                  href="https://www.instagram.com/salonboost.software/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SalonBoost Instagram
                </a>
              </li>
              <li>
                🔹 <span className="fw-bolder">LinkedIn:</span>{" "}
                <a
                  href="https://www.linkedin.com/company/salonboost-software/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SalonBoost LinkedIn
                </a>
              </li>
              <li>
                🔹 <span className="fw-bolder">Twitter (X):</span>{" "}
                <a href="https://x.com/BoostSalon00" target="_blank" rel="noopener noreferrer">
                  SalonBoost Twitter
                </a>
              </li>
            </ul>

            <h3>📲 Chat with Us on WhatsApp</h3>
            <p className="fs-5 ps-5">
              Need quick assistance? Click the button below to chat with our team directly on
              WhatsApp!
            </p>
            <div className="ps-5">
              {" "}
              <button
                className="bttn mmt-1 mb-3"
                onClick={() => {
                  openWhatsApp();
                }}
              >
                Book Your Free Strategy Session Now!
              </button>
            </div>

            <p className="fs-5 ps-5">
              🔹 Ready to take your salon business to the next level? Contact us today!
            </p>
          </div>
        </div>
        <Faqs />
        <SecurityFeatures />
        <Footer showTrustpilot={false} />
      </div>
    </>
  );
};

export default ContactUs;
