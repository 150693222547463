import React from "react";
import { CRow, CCol } from "@coreui/react";

const AutomateFeature = () => {
  const features = [
    {
      title: "Billing",
      description:
        "Salonboost salon software empowers you to seamlessly manage GST & Non-GST billing for salon or spa services, beauty products, and packages. Experience the convenience of automated invoice delivery directly to your clients' phones via whatsapp.",
      image: "images/svg/Billing.svg",
    },
    {
      title: "Feedback & rating",
      description:
        "SalonBoost salon software makes collecting client feedback seamless by sending a unique URL via WhatsApp, SMS, or email. Negative reviews stay within SalonBoost for internal improvements, while positive reviews (4 stars and above) are automatically posted on Google, helping you enhance your reputation and grow your business.",
      image: "images/svg/Feedback&rating.svg",
    },
    {
      title: "Automated appointment reminders ",
      description:
        "SalonBoost salon software streamlines client communication with automated appointment reminders sent via WhatsApp, SMS, and email. Reduce no-shows and keep your schedule organized while ensuring a seamless experience for your clients.",
      image: "images/svg/Automated-Appointment.svg",
    },
    {
      title: "Automated Service Reminders ",
      description:
        "SalonBoost salon software keeps your clients engaged with automated service reminders sent via WhatsApp. Remind them about essential recurring services like facials, hair trims, and pedicures, helping them stay on top of their beauty routine while boosting your repeat business.",
      image: "images/svg/icon-remind.SVG",
    },
    {
      title: "Automated Inquiry Management",
      description:
        "Never miss a potential client with SalonBoost’s smart automation! Our system tracks walk-ins and online leads, sending timely follow-up reminders directly to your dashboard. Automated alerts ensure quick responses, boosting your conversion rate by up to 40%.",
      image: "images/svg/InquiryManagement.svg",
    },
    {
      title: "Smart Automated Marketing",
      description:
        "SalonBoost helps you analyze lead sources and client visit history to understand what works best. Use this data to send targeted campaigns via coupons, bulk SMS, and emails—maximizing engagement and boosting client retention effortlessly.",
      image: "images/svg/SmartMarketing.svg",
    },
    {
      title: "AI-Powered Daily Reports",
      description:
        "Stay ahead with SalonBoost’s automated daily reports! Get real-time insights on billing, walk-ins, and expenses delivered straight to your whatsapp. Leverage AI-driven analytics to track trends, optimize performance, and make data-backed decisions effortlessly.",
      image: "images/svg/DailyReports.svg",
    },
    {
      title: "Smart Performance Insights",
      description:
        "SalonBoost’s AI-powered reports go beyond numbers—analyzing trends in revenue, client visits, and expenses. Get automated insights delivered to your inbox, helping you make data-driven decisions to boost efficiency and profitability.",
      image: "images/svg/Insights.svg",
    },
    {
      title: "Predictive Business Analytics",
      description:
        "Leverage AI-driven reports that don’t just track past performance but predict future trends. SalonBoost helps you identify peak hours, client preferences, and revenue patterns, enabling you to optimize staffing, marketing, and services proactively.",
      image: "images/svg/PredictiveBuisness.svg",
    },
  ];

  return (
    <div
      id="features"
      className="pt-5 mt-5"
      style={{
        backgroundColor: " #512DA8",
      }}
    >
      <CCol className="p-lg-5 w-75 m-auto text-white">
        <h2 className="text-center mb-4">
          Best Salon Management Software to Automate Your Salon & Spa Effortlessly
        </h2>
        <CRow className="g-4">
          {features.map((feature, index) => (
            <CCol xs={12} sm={6} md={4} key={index}>
              <img
                src={feature.image}
                alt={feature.title}
                className="mb-3"
                style={{ width: "80px", height: "80px" }}
              />
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </CCol>
          ))}
        </CRow>
      </CCol>
    </div>
  );
};

export default AutomateFeature;
