import React from "react";

const SecurityFeatures = () => {
  return (
    <>
      <section id="security-features" style={styles.section}>
        <div className="w-75 m-auto">
          <h2 style={styles.heading}>Security Features</h2>
          <div style={styles.container}>
            <div style={styles.item}>
              <img
                src="images/owasp.jpg"
                alt="OWASP Logo"
                style={styles.image}
              />
              <p>This site is audited for OWASP top 10 vulnerabilities.</p>
            </div>
            <div style={styles.item}>
              <img
                src="images/letsencrypt.jpg"
                alt="Let’s Encrypt Logo"
                style={styles.image}
              />
              <p>
                This site uses a Let’s Encrypt SSL certificate and is accessible
                only through HTTPS.
              </p>
            </div>
            <div style={styles.item}>
              <img
                src="images/pci.jpg"
                alt="PCI DSS Logo"
                style={styles.image}
              />
              <p>
                This site complies with PCI DSS self-assessment for secure
                payment processing.
              </p>
            </div>
            <div style={styles.item}>
              <img
                src="images/hackerone.jpg"
                alt="Bug Bounty Logo"
                style={styles.image}
              />
              <p>
                We have a Responsible Disclosure Policy for reporting security
                issues.
              </p>
            </div>
            <div style={styles.item}>
              <img
                src="images/pdpb.jpg"
                alt="The Indian Personal Data Protection Bill"
                style={styles.image}
              />
              <p>
                We follow India's Data Protection Bill guidelines to protect
                user data.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const styles = {
  section: {
    backgroundColor: " #fffdc742",
    padding: "20px",
    margin: "auto",
    width:"100%",
  
  },
  heading: {
    textAlign: "center",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "40px",
  },
  item: {
    flex: "1 1 calc(33% - 40px)",
    textAlign: "center",
    marginBottom: "20px",
  },
  image: {
    height: "50px",
    marginBottom: "10px",
  },
};

export default SecurityFeatures;
