import styled from "styled-components";
import { AiOutlineLoading } from "react-icons/ai";


export const LoadingIcon = styled(AiOutlineLoading)`
  animation: spin 1s linear infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;