import React from "react";
import { Helmet } from "react-helmet-async";
import TopNavbar from "./Header/TopNavbar";
import Footer from "./Footer";
import BottomHero from "./Header/BottomHero";
import Faqs from "./Faqs";
import SecurityFeatures from "./SecurityFeatures";

const Dummy = ({ route }) => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://salonboost.online/${route}`} />
      </Helmet>
      <TopNavbar />
      <BottomHero />
      <Faqs />
      <SecurityFeatures />
      <Footer />
    </>
  );
};

export default Dummy;
